import axios from 'axios'
import { Alert } from 'react-bootstrap'
import Cookies from 'universal-cookie'

const data = {
  sponsors: [],
  internalState: [],
  rutsMonitoring: { result: 0, list: [] },
  optionsMonitoring: [],
  closeModal: { show: true, message: "" },
  getUseDocsTotal: [],
  getUseDocsMonth: [],
  getUseDocsByUsers: []
}

// types
const GET_SPONSOR_SUCCESS = 'GET_SPONSOR_SUCCESS' //general
const GET_INTERNAL_STATE_SUCCESS = 'GET_INTERNAL_STATE_SUCCESS' //general
const GET_MONITORING_RUT_SUCCESS = 'GET_MONITORING_RUT_SUCCESS'
const GET_MONITORING_OPTIONS_SUCCESS = 'GET_MONITORING_OPTIONS_SUCCESS'
const UPDATE_MONITORING_RUT_SUCCESS = 'UPDATE_MONITORING_RUT_SUCCESS'
const UPDATE_CLOSE_MODAL_SUCCESS = 'UPDATE_CLOSE_MODAL_SUCCESS'
const GET_USE_DOCS_TOTAL = 'GET_USE_DOCS_TOTAL'
const GET_USE_DOCS_MONTH = 'GET_USE_DOCS_MONTH'
const GET_USE_DOCS_BY_USER = 'GET_USE_DOCS_BY_USER'

// reducer
export default function generalReducer(state = data, action) {
  switch (action.type) {

    case GET_SPONSOR_SUCCESS:
      return { ...state, sponsors: action.payload }
    case GET_INTERNAL_STATE_SUCCESS:
      return { ...state, internalState: action.payload }
    case GET_MONITORING_RUT_SUCCESS:
      return { ...state, rutsMonitoring: action.payload }
    case GET_MONITORING_OPTIONS_SUCCESS:
      return { ...state, optionsMonitoring: action.payload }
    case UPDATE_MONITORING_RUT_SUCCESS:
      return { ...state, rutsMonitoring: action.payload }
    case UPDATE_CLOSE_MODAL_SUCCESS:
      return { ...state, closeModal: action.payload }
    case GET_USE_DOCS_TOTAL:
      return { ...state, getUseDocsTotal: action.payload }
    case GET_USE_DOCS_MONTH:
      return { ...state, getUseDocsMonth: action.payload }
    case GET_USE_DOCS_BY_USER:
      return { ...state, getUseDocsByUsers: action.payload }
    default:
      return state
  }
}


export const getSponsorsAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_SPONSOR_SUCCESS,
    payload: []
  })
  try {
    const RES = await axios.get(window.API_GET_SPONSORS)
    if (RES.status === 200) {
      dispatch({
        type: GET_SPONSOR_SUCCESS,
        payload: RES.data
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const getInternalStateAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_INTERNAL_STATE_SUCCESS,
    payload: []
  })
  try {
    const RES = await axios.get(window.API_GEN_INTERNAL_STATE)
    if (RES.status === 200) {
      dispatch({
        type: GET_INTERNAL_STATE_SUCCESS,
        payload: RES.data
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const getRutMonitoringAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_MONITORING_RUT_SUCCESS,
    payload: { result: 1, list: [] }
  })
  try {
    const RES = await axios.get(window.API_GET_RUT_MONITORING)
    if (RES.status === 200) {
      dispatch({
        type: GET_MONITORING_RUT_SUCCESS,
        payload: { result: RES.status, list: RES.data }
      })
    } else {
      dispatch({
        type: GET_MONITORING_RUT_SUCCESS,
        payload: { result: RES.status, list: [] }
      })
    }
  } catch (error) {
    dispatch({
      type: GET_MONITORING_RUT_SUCCESS,
      payload: { result: 3, list: [] }
    })
  }
}


export const getOptionsMonitoringAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_MONITORING_OPTIONS_SUCCESS,
    payload: []
  })
  try {
    const RES = await axios.get(window.API_GET_RUT_MONITORING_OPTIONS)
    if (RES.status === 200) {
      dispatch({
        type: GET_MONITORING_OPTIONS_SUCCESS,
        payload: RES.data
      })
    }
  } catch (error) {
    console.log(error)
  }
}


export const updateMonitoringRutAction = (DATA) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_MONITORING_RUT_SUCCESS,
    payload: { result: 1, list: [] }
  })
  try {

    const cookies = new Cookies()
    DATA.userId = cookies.get('user_id')

    const RES = await axios.post(window.API_UPDATE_MONITORING_RUT, DATA)
    if (RES.status === 200) {
      dispatch({
        type: UPDATE_MONITORING_RUT_SUCCESS,
        payload: { result: RES.status, list: RES.data }
      })
      dispatch({
        type: UPDATE_CLOSE_MODAL_SUCCESS,
        payload: { show: false, message: "Cambios aplicados, Actualizando lista..." }
      })
    } else {
      dispatch({
        type: UPDATE_MONITORING_RUT_SUCCESS,
        payload: { result: RES.status, list: [] }
      })
      dispatch({
        type: UPDATE_CLOSE_MODAL_SUCCESS,
        payload: { show: false, message: "No se pudo actualizar" }
      })
    }

  } catch (error) {
    dispatch({
      type: GET_MONITORING_RUT_SUCCESS,
      payload: { result: 3, list: [] }
    })
  }
}


export const getUseDocsTotalAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_USE_DOCS_TOTAL,
    payload: []
  })
  try {
    const RES = await axios.get(window.USE_DOCUMENTS + "?type=total")
    console.log(RES)
    if (RES.status === 200) {
      if (RES.data.error) {
        dispatch({
          type: GET_USE_DOCS_TOTAL,
          payload: []
        })
      } else {
        dispatch({
          type: GET_USE_DOCS_TOTAL,
          payload: RES.data
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const getUseDocsMonthAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_USE_DOCS_MONTH,
    payload: []
  })

  try {
    const RES = await axios.get(window.USE_DOCUMENTS + "?type=mensual")
    if (RES.status === 200) {
      dispatch({
        type: GET_USE_DOCS_MONTH,
        payload: RES.data
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const getUseDocsByUserAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_USE_DOCS_BY_USER,
    payload: []
  })

  try {
    const RES = await axios.get(window.USE_DOCUMENTS_BY_USER)
    if (RES.status === 200) {
      dispatch({
        type: GET_USE_DOCS_BY_USER,
        payload: RES.data
      })
    }
  } catch (error) {
    console.log(error)
  }
}
//https://localhost:63889/api/General/UseOfAutomaticDocumentsByDocs

