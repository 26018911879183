import React, { Fragment, useState, useEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Form, Col, Row, Collapse, Button, Alert, Spinner, InputGroup } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { NavLink, json } from 'react-router-dom'
import tableCustomStyles from '../components/TableStyle'
import AgregarCausas from '../components/Causas/AgregarCausas'
import CreatableSelect from 'react-select/creatable'

import { useDispatch, useSelector } from 'react-redux'
import { getCausasAction, getCausasFilterAction, getInfoCase } from '../Redux/CausasDucks'

import { useForm, Controller } from 'react-hook-form'

import { JsonToExcel } from "react-json-to-excel"

const PortafolioCausasPage = () => {
	const { register, handleSubmit, control } = useForm()
    const dispatch = useDispatch()
    
    const [buttonPopup, setButtonPopup] = useState(false);
    const [open, setOpen] = useState(false)
    const [grupo, setGrupo] = useState([])
    const [tribunales, setTribunales] = useState([])
    const [causasXls, setCausasXls] = useState([])
    

	const causas = useSelector(store => store.causas.causas)
	const infoCase = useSelector(store => store.causas.infoCase)
	const causasBusqueda = useSelector(store => store.causas.causasBusqueda)

    const onSubmit = (event) => dispatch(getCausasAction(event))
	const onSubmitFilter = (event) => {
        event.groupId = event.groupId? event.groupId.value:null;
        event.tribunalId = event.tribunalId? event.tribunalId.value:null;
        dispatch(getCausasFilterAction(event))
    }
    const OpenCloseModal = (e) => { setButtonPopup(!buttonPopup) }

    useEffect(() => {
        if(infoCase){
            const aux = infoCase.groups.map( item => { return { value: item.id , label : item.description }; })
            setGrupo(aux)
            const tri = infoCase.tribunales.map( item => { return { value: item.id , label : item.nombre }; })
            setTribunales(tri)
        }
     }, [infoCase]);

     
    
    useEffect(() => { 
        dispatch(getCausasAction({text:""}))
        dispatch(getInfoCase())
        

     },[])



    const columnasCausas = [
        {
            name: "Id", cell: (row) => 
            <NavLink className="button btn-primary p-2 rounded" to={"/portafolio/causas/"+row.id} end><Icon.JournalBookmark/> {row.id}</NavLink>,
            ignoreRowClick: true,
            button: true
        },
        //{ name: "Id", selector: "id" },
        { name: "Código", selector: "code" },
        {name: "Causa", cell: (row) => <Fragment> {row.title} </Fragment>},
        {name: "Juzgado", cell: (row) => <Fragment> {row.juzgado} </Fragment>},
        {name: "Cliente", cell: (row) => <Fragment> {row.client.name}<br/> {row.client.identifier}<br/> {row.client.telephone} </Fragment>},
        {name: "SAC", cell: (row) => <Fragment> {row.client.sac}</Fragment>},
        {name: "grupo", cell: (row) => <Fragment> {row.grupo}</Fragment>},
    ];

    const paginationOptions = {
        rowsPerPageText: 'Mostrar por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

  
     
    useEffect(() => { 
        if (causas.length > 0) {
            let aux = causas.map(causa => ({
                ...causa,
                telephone: causa.client.telephone,
                identifier: causa.client.identifier,
                client: causa.client.name
            }));
            setCausasXls(aux);
        }
    }, [causas]); 
    

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
                <section>
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className='box'>
                                <div className='row row-cols-md-auto g-10 align-items-center'>
                                    <div className="col-md-10">
                                        <h2 className="page-heading">Causas</h2>
                                        <p className='text-info fs-5'>*Se pueden crear nuevas causas</p>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-9">
                                    <form onSubmit={handleSubmit(onSubmit)} className="row row-cols-md-auto">
                                        <InputGroup>
                                            <Form.Control placeholder="Buscar idCausa, Rol N°, Rut Cliente, Nombre Cliente, Email Cliente, Telefono Cliente..." {...register("text")}/>
                                            <Button type="submit" variant="primary"><Icon.Search/> Buscar</Button>
                                            <Button variant="outline-secondary" onClick={() => setOpen(!open)} aria-controls="collapse-form" aria-expanded={open}><Icon.Funnel/> Avanzado</Button>
                                            <Button variant="success" onClick={() => setButtonPopup(true)}><Icon.NodePlusFill/> Agregar Causa</Button>
                                        </InputGroup>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Collapse in={open} className="card-body">
                            <Form onSubmit={handleSubmit(onSubmitFilter)}>
                                <Row>
                                    <Col lg={12}>
                                        <Form.Group as={Row} className="mb-2">
                                            <Form.Label column sm="2"> Rut cliente </Form.Label>
                                            <Col sm="3"> <Form.Control type="text" className="form-control" {...register("rut")}/> </Col>
                                            <Form.Label column sm="2"> Nombre Cliente </Form.Label>
                                            <Col sm="3"> <Form.Control type="text" className="form-control" {...register("name")}/> </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-2">
                                            <Form.Label column sm="2"> Fecha Creación Inicial </Form.Label>
                                            <Col sm="3"> <Form.Control type="date" className="form-control" {...register("createAtInicial")}/> </Col>
                                            <Form.Label column sm="2"> Fecha Creación Final </Form.Label>
                                            <Col sm="3"> <Form.Control type="date" className="form-control" {...register("createAtFinal")}/> </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-2">
                                            <Form.Label column sm="2"> Grupo </Form.Label>
                                            <Col sm="3"> <Controller name="groupId" render={({ field }) => (<CreatableSelect {...field} options={grupo} isClearable /> )} control={control} rules={{ required: false }} /> </Col>
                                            <Form.Label column sm="2"> Tribunales </Form.Label>
                                            <Col sm="3"> <Controller name="tribunalId" render={({ field }) => (<CreatableSelect {...field} options={tribunales} isClearable /> )} control={control} rules={{ required: false }} /> </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="pt-2 mb-2 d-flex justify-content-center">
                                            <Button className='w-25' type="submit" variant="primary"><Icon.Search/></Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Collapse>

                        <div className="card-body text-muted">
                            {causas.length>0 ?  
                                <Fragment>
                                    <div className="col-3 ">
                                        <JsonToExcel
                                            btnClassName="btn btn-outline-primary border border-primary text-info btn-sm"
                                            title={"Descargar"}
                                            data={causasXls}
                                            fileName="sample-file"
                                            btnColor={null}
                                        /> 
                                    </div>
                                    <Form>
                                        <div>
                                            <DataTable className='table'
                                                title="Causas"
                                                columns={columnasCausas}
                                                customStyles={tableCustomStyles}
                                                data={causas}
                                                pagination
                                                paginationComponentOptions={paginationOptions}/>
                                        </div>
                                    </Form>
                                </Fragment>
                                :
                                causasBusqueda.status!=0 ? <Alert variant='light' className='pb-0 mb-2 fs-4'> {causasBusqueda.status==1? <Spinner animation="grow" variant="primary" />:null} {causasBusqueda.message} </Alert>: null
                            }
                        </div>
                    </div>
                </section>
            </div>
            
        <AgregarCausas OpenCloseModal={OpenCloseModal} buttonPopup={buttonPopup} data={null}/>
        </Fragment>
    );
}

export default PortafolioCausasPage;
