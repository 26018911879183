import React, { Fragment, useState } from 'react'
import * as Icon from 'react-bootstrap-icons'
import {
    ModalBody, ModalFooter, Button, Modal, ModalHeader, Col, Row, Form, Tab, Tabs, Toast,
    InputGroup, Table, Alert
} from 'react-bootstrap'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import { useNavigate } from 'react-router-dom'

//import { getAdminClientsAction, getAdminClientsAdvancedAction } from '../Redux/CausasDucks'
import { getAdminClientsAction, getListUsers, CreateNewCase } from '../../Redux/CausasDucks'
import { getSponsorsAction } from '../../Redux/GeneralDucks'
import Select from 'react-select'


const AgregarCausas = ({ OpenCloseModal, buttonPopup, data }) => {
    const navigate = useNavigate()

    const { register: registerClient, handleSubmit: handleSubmitClient } = useForm()
    const { register: registerCase, handleSubmit: handleSubmitCase, control, setValue, watch } = useForm()
    const { handleSubmit: handleSubmitUsers, control: controlUsers } = useForm()

    const dispatch = useDispatch()

    const [groups, setGroups] = useState([])
    const [caseTypes, setCaseTypes] = useState([])
    const [caseTypesDetail, setCaseTypesDetail] = useState([])
    const [internalStates, setInternalStates] = useState([])
    const [lineaNegocios, setLineaNegocios] = useState([])
    const [procesess, setProcesess] = useState([])
    const [tribunales, setTribunales] = useState([])
    const [clienteCausa, setClienteCausa] = useState({ id: null, nombre: null, rut: null })
    const [usersGeneral, setUsersGeneral] = useState([])
    const [usersId, setusersId] = useState([])
    const [showToast, setShowToast] = useState(false)
    const [messageToast, setMessageToast] = useState("")
    const [showToastUsers, setShowToastUsers] = useState(false)
    const [messageToastUsers, setMessageToastUsers] = useState("")
    const [sponsor, setSponsor] = useState([])

    const infoCase = useSelector(store => store.causas.infoCase)
    const clientes = useSelector(store => store.causas.clientes)
    const usuarios = useSelector(store => store.causas.usuarios)
    const sponsorsList = useSelector(store => store.general.sponsors)

    const sendNewCase = useSelector(store => store.causas.sendNewCase)
    const messageSendNewCase = useSelector(store => store.causas.messageSendNewCase)
    const newCase = useSelector(store => store.causas.newCase)
    const [NotExactMatch, setNotExactMatch] = useState(null)

    useEffect(() => {
        if (data) {
            onSearchClient({ "text": data.identifier })

            const exactMatch = tribunales.find(t => t.label == data.tribunal);
            if (exactMatch) {
                setValue('tribunalId', exactMatch);
            } else {
                setNotExactMatch(data.tribunal)
            }

        }
    }, [data])


    useEffect(() => {
        console.log(infoCase)

        if (infoCase) {
            const aux_caseTypes = infoCase.caseTypes.map(item => { return { value: item.id, label: item.description }; })
            setCaseTypes(aux_caseTypes)

            let aux_group = []
            infoCase.groups.map(item => { if (item.description.search("SAC") < 0) aux_group.push({ value: item.id, label: item.description }) })
            setGroups(aux_group)

            const aux_caseTypesDetail = infoCase.caseTypesDetail.map(item => { return { value: item.id, label: item.description }; })
            setCaseTypesDetail(aux_caseTypesDetail)

            const aux_internalStates = infoCase.internalStates.map(item => { return { value: item.id, label: item.description }; })
            setInternalStates(aux_internalStates)

            const aux_lineaNegocios = infoCase.lineaNegocios.map(item => { return { value: item.id, label: item.descripcion }; })
            setLineaNegocios(aux_lineaNegocios)

            const aux_procesess = infoCase.procesess.map(item => { return { value: item.id, label: item.description }; })
            setProcesess(aux_procesess)

            const aux_tribunales = infoCase.tribunales.map(item => { return { value: item.id, label: item.nombre }; })
            setTribunales(aux_tribunales)
        }
    }, [infoCase]);



    useEffect(() => { dispatch(getSponsorsAction()) }, []);

    useEffect(() => {
        if (sponsorsList.length > 0)
            setSponsor(sponsorsList.map(item => { return { value: item.id, label: item.nombre }; }))
    }, [sponsorsList])


    useEffect(() => { }, [clientes]);

    useEffect(() => {
        //sendNewTask
        if (messageSendNewCase) {
            setShowToast(true)
            setMessageToast(messageSendNewCase)
        }
    }, [messageSendNewCase])

    useEffect(() => {
        //sendNewTask
        if (newCase)
            setTimeout(() => {
                navigate('/portafolio/causas/' + newCase)
            }, 2000)
    }, [newCase])

    useEffect(() => {
        if (usuarios.length > 0) {
            let gen = []
            usuarios.map(item => {
                if (item.active) gen.push({ value: item.id, label: item.nombre })
            })
            setUsersGeneral(gen)
        } else {
            dispatch(getListUsers())
        }
    }, [usuarios]);

    const onSubmitUsers = (event) => {
        setShowToastUsers(true)
        setMessageToastUsers("Usuarios agregados")
        setusersId(event.usersId)
    }

    const onSearchClient = (event) => dispatch(getAdminClientsAction(event))
    const selectClient = (RUT) => {
        var aux = [];
        clientes.forEach(el => {
            aux.push(el.result)
        });
        const client = aux.find(({ rut }) => rut === RUT);
        setClienteCausa({ id: client.clientId, nombre: client.nameClient, rut: client.rut })
    }
    const onCreateCase = (event) => {
        var timeOut = 0
        if (data) {
            OpenCloseModal(data)
            timeOut = 500
        }
        setTimeout(() => {

            event.clientId = clienteCausa.id
            event.usersId = usersId
            console.log(event)
            if (event.clientId == null) {
                setShowToast(true)
                setMessageToast("Debe asignar CLIENTE a la CAUSA")
                return
            }
            if (event.usersId.length == 0) {
                setShowToast(true)
                setMessageToast("Debe asignar USUARIOS a la CAUSA")
                return
            }

            if (event.groupId) event.groupId = event.groupId.value
            if (event.caseTypeId) event.caseTypeId = event.caseTypeId.value
            if (event.tribunalId) event.tribunalId = event.tribunalId.value
            if (event.caseTypeDetailId) event.caseTypeDetailId = event.caseTypeDetailId.value
            if (event.internalStatesId) event.internalStatesId = event.internalStatesId.value
            if (event.lineaNegocioId) event.lineaNegocioId = event.lineaNegocioId.value
            if (event.processId) event.processId = event.processId.value
            if (event.sponsorId) event.sponsorId = event.sponsorId.value

            dispatch(CreateNewCase(event));
        }, timeOut);
    }


    return (
        <Modal show={buttonPopup} size="lg" aria-labelledby="example-custom-modal-styling-title">
            <ModalHeader>
                <h3 className='w-auto'>Causa Nueva</h3>
                <h4 className='w-auto'>{clienteCausa.id ? (clienteCausa.rut + " : " + clienteCausa.nombre) : null}</h4>
                <Icon.XCircleFill onClick={()=>OpenCloseModal(null)} className="me-1 fs-3" />
            </ModalHeader>
            <ModalBody>
                <Tabs defaultActiveKey="client" id="new-case" className="mb-3" justify >
                    <Tab eventKey="client" title="Asignar Cliente">
                        <Row className='pt-3'>
                            <h5 className='w-100 text-center mb-2'>Asignar Cliente</h5>
                            <Col lg={12}>
                                <Row className="mb-4">
                                    <Col className="form-outline">
                                        <Form onSubmit={handleSubmitClient(onSearchClient)} className="row row-cols-md-auto mb-4">
                                            <InputGroup>
                                                <Form.Control placeholder="Buscar Rut, Nombre, Telefono, Dirección..." defaultValue={data ? data.identifier : ""} {...registerClient("text")} />
                                                <Button type="submit" variant="primary"><Icon.Search /> Buscar</Button>
                                            </InputGroup>
                                        </Form>
                                        <Table striped bordered hover size="sm">
                                            <thead> <tr> <th>Seleccionar</th> <th>Rut</th> <th>Nombre</th> </tr> </thead>
                                            <tbody>
                                                {clientes?.map(cl => (
                                                    <tr>
                                                        <td><Button variant="success" type="button" onClick={() => selectClient(cl.result.rut)} className="me-1"  >{cl.result.rut == clienteCausa.rut ? "Seleccionado" : "Seleccionar"} </Button></td>
                                                        <td>{cl.result.rut}</td>
                                                        <td>{cl.result.nameClient}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="longer-tab" title="Asignar Usuarios">
                        <Row className="mt-4">
                            <Col lg={12}>
                                <Row className="mb-4">
                                    <Col className="form-outline">
                                        <Form onSubmit={handleSubmitUsers(onSubmitUsers)} >
                                            <Controller rules={{ required: true }} control={controlUsers} name="usersId" render={({ field: { onChange, value, name, ref } }) => (<>
                                                <Form.Label className="form-label d-flex">Usuarios <div className='text-red ms-1'> obligatorio</div></Form.Label>
                                                <Select inputRef={ref} classNamePrefix="addl-class" options={usersGeneral} value={usersGeneral.find((c) => c.value === value)} onChange={(val) => onChange(val.map((c) => c.value))} isMulti />
                                            </>)} />
                                            <Button variant="primary" className='mt-3 w-auto' type="submit">Confirmar Usuarios</Button>
                                        </Form>
                                        <Toast show={showToastUsers} onClose={(e) => setShowToastUsers(false)} bg="success" className='mt-2' delay={4000} autohide>
                                            <Toast.Body> {messageToastUsers} </Toast.Body>
                                        </Toast>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="case" title="Datos de la Causa">
                        <Form onSubmit={handleSubmitCase(onCreateCase)}>
                            <Row className='pt-3'>
                                <Col lg={12}>
                                    <Row className="mb-2">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Cliente Asignado</Form.Label>
                                            <Form.Control value={clienteCausa.nombre} placeholder="No Seleccionado" readOnly />
                                        </Col>
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Rut Cliente</Form.Label>
                                            <Form.Control value={clienteCausa.rut} placeholder="No Seleccionado" readOnly required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label d-flex">Grupo <div className='text-red ms-1'> obligatorio</div></Form.Label>
                                            <Controller name="groupId" render={({ field }) => (<CreatableSelect {...field} options={groups} isClearable />)} control={control} rules={{ required: true }} />
                                        </Col>
                                        <Col className="form-outline">
                                            <Form.Label className="form-label d-flex">Tipo de Causa <div className='text-red ms-1'> obligatorio</div></Form.Label>
                                            <Controller name="caseTypeId" render={({ field }) => (<CreatableSelect {...field} options={caseTypes} isClearable />)} control={control} rules={{ required: true }} />
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Rol</Form.Label>
                                            <Form.Control type="text" placeholder="Rol Causa" className="form-control" defaultValue={data ? data.rol : ""} {...registerCase("code")} />
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Titulo</Form.Label>
                                            <Form.Control type="text" placeholder="Titulo Causa" className="form-control" defaultValue={data ? data.labeled : ""} {...registerCase("title")} />
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">{NotExactMatch ? <strong>Seleccione el {NotExactMatch}</strong  > : "Tribunal"}</Form.Label>
                                            <Controller name="tribunalId" render={({ field }) => (<CreatableSelect {...field} options={tribunales} isClearable />)} control={control} rules={{ required: NotExactMatch ? true : false }} />
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Detalle Tipo de Causa</Form.Label>
                                            <Controller name="caseTypeDetailId" render={({ field }) => (<CreatableSelect {...field} options={caseTypesDetail} isClearable />)} control={control} rules={{ required: false }} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2" hidden>
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Estado Interno Inicial</Form.Label>
                                            <Controller name="internalStatesId" render={({ field }) => (<CreatableSelect {...field} options={internalStates} isClearable />)} control={control} rules={{ required: false }} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Linea Negocios</Form.Label>
                                            <Controller name="lineaNegocioId" render={({ field }) => (<CreatableSelect {...field} options={lineaNegocios} isClearable />)} control={control} rules={{ required: false }} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Tipo de Procedimiento</Form.Label>
                                            <Controller name="processId" render={({ field }) => (<CreatableSelect {...field} options={procesess} isClearable />)} control={control} rules={{ required: false }} />
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col className="form-outline">
                                            <Form.Label className="form-label">Patrocinante <div className='text-red ms-1'> obligatorio</div></Form.Label>

                                            <Controller name="sponsorId" render={({ field }) => (<CreatableSelect {...field} options={sponsor} isClearable />)} control={control} rules={{ required: true }} />
                                        </Col>
                                    </Row>

                                    <Button className="mt-3 mb-3" variant="success" type="submit"> Guardar Causa</Button>


                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                </Tabs>
            </ModalBody>
            <Toast show={showToast} onClose={(e) => setShowToast(false)} bg="info" className='position-fixed top-0 end-0 mt-3 me-3' style={{ zIndex: "10000" }} delay={4000} autohide>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Atencion!</strong>
                </Toast.Header>
                <Toast.Body> {messageToast} </Toast.Body>
            </Toast>
        </Modal>
    )
}
export default AgregarCausas;
